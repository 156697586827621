import { graphql } from "gatsby"
import React, { useState } from 'react';
import Bio from "../components/bio"
import Layout from "../components/layout/index"
import SEO from "../components/seo"
import PostList from "../components/post/post-list"
import { post as PostMappers } from "../utils/mappers"
import BlogSwitch from "../components/blog-switch"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description
  const { fromGatsbyNode } = PostMappers
  const [postCategory, setPostCategory] = useState(undefined);

  const applyPostCategoryFilter = (posts) => {
    return postCategory ? 
      posts.filter(
        rawPost => rawPost.frontmatter.category.includes(postCategory)
      ) 
      : posts;
  }
  const posts = applyPostCategoryFilter(
    data.allMarkdownRemark.edges
      ? data.allMarkdownRemark.edges.map(fromGatsbyNode)
      : []
  )
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" description={siteDescription} />
      <Bio />
      <BlogSwitch postCategory={postCategory} onPostCategorySelected={setPostCategory}/>
      <PostList posts={posts} />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            readingTime
            tags
            category
          }
        }
      }
    }
  }
`
