import React from 'react'

const blogSwitchStyle = {
  containerStyle: { 
    cursor: "pointer",
    display: "flex", 
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    color: "black"
  },
  switchPassiveStyle: {
    color: "black"
  }
}

const BlogSwitch = ({postCategory, onPostCategorySelected}) => {
  
  const postSwitchStyle = (switchCategory) => {
    return switchCategory === postCategory ? {} : blogSwitchStyle.switchPassiveStyle;
  }

  return (
  <div style={blogSwitchStyle.containerStyle}>
    <span>
      <a style={postSwitchStyle(undefined)} onClick={()=>{onPostCategorySelected(undefined)}}>
        <span role='img' aria-label='all posts'>
          📝
        </span>
        {" "}all posts
      </a>
      {" | "}
      <a style={postSwitchStyle('math')} onClick={()=>{onPostCategorySelected('math')}}>
        <span role='img' aria-label='math'>
          ✍️
        </span>
        {" "}mostly math
      </a>
      {" | "}
      <a style={postSwitchStyle('computer-science')} onClick={()=>{onPostCategorySelected('computer-science')}}>
        <span role='img' aria-label='computer science'>
          🖥
        </span>
        {" "}mostly computer science
      </a>
    </span>
  </div>);
}

export default BlogSwitch